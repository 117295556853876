
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'TopBar',
  components: {
    LazyHydrate
  }
}

